import {
  Form,
  Input,
  Button,
  message,
  Col,
  Row,
  Select,
  DatePicker,
  InputNumber,
  Upload,
} from "antd";
import { Card } from "antd";
import { Divider } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { UploadOutlined } from "@ant-design/icons";
import logo from "../assets/logo.webp";
import config from "../config.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

// TODO: heic to jpg
// TODO: add signature pad
// TODO: GetYourFakeID mutiple domain buy to avoid bad user experience
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const OrderForm = ({ cartItemsCount, setCartItemsCount }) => {
  const { Option } = Select;

  const [formValues, setFormValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    eyes: "",
    hair: "",
    gender: "",
    dob: "",
    weight: "",
    height: "",
    zip_code: "",
    address: "",
    city: "",
    upload_picture: "",
    upload_signature: "",
    product: "",
    product_name: "",
  });
  const [states, setStates] = useState([]); // 保存州列表
  const [signatureVisible, setSignatureVisible] = useState(true); // 控制签名板的显示
  const signatureRef = useRef(null);
  const [signatureImage, setSignatureImage] = useState(null);

  const handleSelectStates = (name, state, product_name, value) => {
    // 根据 value 查找对应的 state 对象

    const selectedState = states.find((state) => state.id === value);
    if (selectedState) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: selectedState.id, // 更新对应选择框的值
      }));
      setFormValues((prevValues) => ({
        ...prevValues,
        [product_name]: selectedState.name, // 更新对应选择框的值
      }));
      setFormValues((prevValues) => ({
        ...prevValues,
        [state]: selectedState.state, // 更新对应选择框的值
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // 更新对应输入框的值
    }));
  };
  // 处理 Select 的变化
  const handleSelectChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // 更新对应选择框的值
    }));
  };
  // 处理 DatePicker 的变化
  const handleDateChange = (date, dateString) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      dob: dateString, // 更新日期值
    }));
  };
  // 处理 InputNumber 的变化
  const handleNumberChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      weight: value, // 更新 weight 值
    }));
  };
  // 处理上传图片
  const beforeUploadSignature = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    const formData = new FormData();
    formData.append("upload_signature", file);
    const token = localStorage.getItem("accessToken");

    // 使用 axios 手动发送请求
    axios
      .post(`${config.baseUrl}/api/carts/upload/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // 添加 token 到请求头
        },
      })
      .then((response) => {
        const imagePreviewUrl = URL.createObjectURL(file);
        handleSelectChange("signature", imagePreviewUrl);
      })
      .catch((error) => {
        message.error("Upload failed, please try again later");
      });
    return false;
  };
  // 文件大小限制为2MB, 文件类型限制为图片格式
  const beforeUploadPicture = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    const formData = new FormData();
    formData.append("upload_picture", file);
    const token = localStorage.getItem("accessToken");

    // 使用 axios 手动发送请求
    axios
      .post(`${config.baseUrl}/api/carts/upload/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // 添加 token 到请求头
        },
      })
      .then((response) => {
        const imagePreviewUrl = URL.createObjectURL(file);
        handleSelectChange("picture", imagePreviewUrl);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          message.error("Please login first");
          navigate("/login");
        } else {
          message.error("Upload failed, please try again later");
        }
      });
    return false;
  };
  //onfinish
  const onFinish = (values) => {
    delete values["upload_picture"];
    delete values["upload_signature"];
    // 将日期格式转换为 mm/dd/yyyy
    if (values.dob) {
      const date = new Date(values.dob);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      values.dob = `${year}-${month}-${day}`;
    }
    const token = localStorage.getItem("accessToken");
    axios
      .post(`${config.baseUrl}/api/carts/create/`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        message.success("Order added to cart successfully!");
        // 使用 navigate 刷新 /order 页面
        setCartItemsCount(cartItemsCount + 1);
        // 滚动到页面顶部
        window.scrollTo({
          top: 0,
          behavior: "smooth", // 使滚动平滑
        });
      })
      .catch((error) => {
        message.error("Failed to add order to cart.");
      });
  };
  //onfinishfailed
  const onFinishFailed = (errorInfo) => {
    message.error("Failed to add order to cart.");
  };
  // 在组件挂载时发起请求
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
    }
    const fetchStates = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/api/products/`);
        setStates(response.data); // 将拉取的数据保存到状态中
      } catch (error) {
        message.error("Failed to fetch states.");
      }
    };

    fetchStates(); // 调用函数发起请求
  }, [navigate]); // 空依赖数组，确保只在组件挂载时执行一次

  const handleCheckout = () => {
    navigate("/checkout");
  };
  //form
  const [form] = Form.useForm();

  const handleSignatureSave = (dataUrl) => {
    // 直接使用原始的签名数据
    const trimmedDataUrl = dataUrl;

    // 将 trimmedDataUrl 转换为 Blob
    fetch(trimmedDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "signature.png", { type: "image/png" });
        const formData = new FormData();
        formData.append("upload_signature", file);

        const token = localStorage.getItem("accessToken");

        // 使用 axios 发送请求
        axios
          .post(`${config.baseUrl}/api/carts/upload/`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            message.success("Signature uploaded successfully");
            handleSelectChange("signature", trimmedDataUrl);
            setSignatureImage(trimmedDataUrl);
            setSignatureVisible(false);
          })
          .catch((error) => {
            message.error("Please login first");
            navigate("/login");
          });
      });
  };

  const handleDrawSignature = (e) => {
    e.preventDefault(); // 阻止默认行为
    setSignatureVisible(true);
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setSignatureImage(null);
      handleSelectChange("signature", "");
      setSignatureVisible(true); // 重新显示签名板
    }
  };

  useEffect(() => {
    if (signatureRef.current) {
      const canvas = signatureRef.current._canvas;
      const ctx = canvas.getContext("2d");

      // 清除整个画布
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [signatureVisible]);

  return (
    <Card
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>Order Now</span>
      }
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      <div>
        <Row style={{ justifyContent: "center" }}>
          <Col span={21}>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              scrollToFirstError
            >
              <Form.Item
                name="product"
                label="PRODUCT"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select state"
                  allowClear
                  onChange={(value) =>
                    handleSelectStates(
                      "product",
                      "state",
                      "product_name",
                      value
                    )
                  }
                >
                  {states.map((state) => (
                    <Option key={state.name} value={state.id}>
                      <img
                        src={state.image || logo} // 使用API返回的图片或默认图片
                        alt={state.name}
                        style={{ width: 35, marginRight: 8 }}
                      />
                      {state.name} ({state.state})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="is_locked" initialValue="true" hidden>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="upload_picture"
                label="PICTURE"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true }]}
              >
                <Upload
                  name="upload_picture"
                  listType="picture"
                  maxCount={1}
                  beforeUpload={beforeUploadPicture}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="upload_signature"
                label="SIGNATURE"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <div>
                  <Upload
                    name="upload_signature"
                    listType="picture"
                    maxCount={1}
                    beforeUpload={beforeUploadSignature}
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                  <div>If left blank, we will make one for you</div>
                  <Button onClick={handleDrawSignature}>Draw Signature</Button>
                </div>
                {signatureImage && (
                  <div style={{ marginTop: "10px" }}>
                    <img
                      src={signatureImage}
                      alt="Signature"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  </div>
                )}
              </Form.Item>

              {/* 签名板 */}
              {signatureVisible && (
                <div>
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                      width: 300,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    onEnd={() =>
                      handleSignatureSave(
                        signatureRef.current.getTrimmedCanvas().toDataURL()
                      )
                    } // 保存签名
                    dotSize={0.8} // 减小点的大小
                    minWidth={0.8} // 减小最小线宽
                    maxWidth={1.5} // 增加最大线宽
                    backgroundColor="rgba(255,255,255,0)"
                    throttle={10}
                    velocityFilterWeight={0.7} // 添加速度滤波权重
                  />
                  <Button onClick={clearSignature}>Clear Signature</Button>
                  <Divider dashed />
                </div>
              )}

              <Form.Item
                name="first_name"
                label="FIRST NAME"
                rules={[{ required: true }]}
              >
                <Input
                  name="first_name"
                  placeholder="First Name"
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item
                name="middle_name"
                label="MIDDLE NAME(optional)"
                rules={[]}
              >
                <Input
                  name="middle_name"
                  onChange={handleInputChange}
                  placeholder="Middle Name"
                />
              </Form.Item>

              <Form.Item
                name="last_name"
                label="LAST NAME"
                rules={[{ required: true }]}
              >
                <Input
                  name="last_name"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item
                name="hair"
                label="HAIR COLOR"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Hair Color"
                  allowClear
                  onChange={(value) => handleSelectChange("hair", value)}
                >
                  <Option value="BLN">BLN-BLOND</Option>
                  <Option value="BLK">BLK-BLACK</Option>
                  <Option value="BAL">BAL-BALD</Option>
                  <Option value="BRN">BRN-BROWN</Option>
                  <Option value="GRY">GRY-GRAY</Option>
                  <Option value="RED">RED-RED</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="eyes"
                label="EYES COLOR"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Eyes Color"
                  allowClear
                  onChange={(value) => handleSelectChange("eyes", value)}
                >
                  <Option value="BLK">BLK-BLACK</Option>
                  <Option value="BLU">BLU-BLUE</Option>
                  <Option value="BRN">BRN-BROWN</Option>
                  <Option value="GRN">GRN-GREEN</Option>
                  <Option value="GRY">GRY-GRAY</Option>
                  <Option value="HAZ">HAZ-HAZEL</Option>
                  <Option value="SDY">SDY-SANDY</Option>
                  <Option value="MUL">MUL-MULTIPLE</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="gender"
                label="GENDER"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Please select gender"
                  allowClear
                  onChange={(value) => handleSelectChange("gender", value)}
                >
                  <Option value="M">MALE</Option>
                  <Option value="F">FEMALE</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="DATE OF BIRTH"
                name="dob"
                rules={[{ required: true }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="MM/DD/YYYY"
                  placeholder="Date Of Birth(mm/dd/yyyy)"
                  onChange={handleDateChange}
                />
              </Form.Item>

              <Form.Item
                name="weight"
                label="WEIGHT(lbs)"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 80,
                    max: 400,
                    message: "Weight must be between 80 and 400",
                  },
                ]}
              >
                <InputNumber
                  min={80}
                  max={400}
                  style={{ width: "100%" }}
                  onChange={handleNumberChange}
                />
              </Form.Item>

              <Form.Item
                name="height"
                label="HEIGHT"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Please select height"
                  allowClear
                  onChange={(value) => handleSelectChange("height", value)}
                >
                  <Option value="5'-00''">5'-00''</Option>
                  <Option value="5'-01''">5'-01''</Option>
                  <Option value="5'-02''">5'-02''</Option>
                  <Option value="5'-03''">5'-03''</Option>
                  <Option value="5'-04''">5'-04''</Option>
                  <Option value="5'-05''">5'-05''</Option>
                  <Option value="5'-06''">5'-06''</Option>
                  <Option value="5'-07''">5'-07''</Option>
                  <Option value="5'-08''">5'-08''</Option>
                  <Option value="5'-09''">5'-09''</Option>
                  <Option value="5'-10''">5'-10''</Option>
                  <Option value="5'-11''">5'-11''</Option>
                  <Option value="6'-00''">6'-00''</Option>
                  <Option value="6'-01''">6'-01''</Option>
                  <Option value="6'-02''">6'-02''</Option>
                  <Option value="6'-03''">6'-03''</Option>
                  <Option value="6'-04''">6'-04''</Option>
                  <Option value="6'-05''">6'-05''</Option>
                  <Option value="6'-06''">6'-06''</Option>
                  <Option value="6'-07''">6'-07''</Option>
                  <Option value="6'-08''">6'-08''</Option>
                  <Option value="6'-09''">6'-09''</Option>
                  <Option value="6'-10''">6'-10''</Option>
                  <Option value="6'-11''">6'-11''</Option>
                  <Option value="7'-00''">7'-00''</Option>
                  <Option value="7'-01''">7'-01''</Option>
                  <Option value="7'-02''">7'-02''</Option>
                  <Option value="7'-03''">7'-03''</Option>
                  <Option value="7'-04''">7'-04''</Option>
                  <Option value="7'-05''">7'-05''</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="address"
                label="ADDRESS"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Address"
                  name="address"
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item name="city" label="CITY" rules={[{ required: true }]}>
                <Input placeholder="City" />
              </Form.Item>

              <Form.Item
                name="zip_code"
                label="ZIPCODE"
                rules={[{ required: true }]}
              >
                <Input
                  name="zip_code"
                  placeholder="Zipcode"
                  onChange={handleInputChange}
                />
              </Form.Item>

              <div
                style={{
                  textAlign: "left",
                  borderRadius: "10px",
                  border: "1px solid lightblue",
                  padding: "10px",
                  margin: "10px auto",
                }}
              >
                <Row style={{ backgroundColor: "lightblue" }}>
                  <Col span={24}>
                    <div
                      style={{
                        color: "white",
                        lineHeight: "30px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {formValues.product_name}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col span={8}>
                    {/*
  左侧的头像跟签名
  */}
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={formValues.picture || logo} // 图像URL
                        alt="User Avatar"
                        style={{ width: "80px", height: "80px" }} // 设置图像大小和形状
                      />
                    </div>

                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <img
                        src={formValues.signature || logo} // 图像URL
                        alt="User Avatar"
                        style={{ width: "80px", height: "43px" }} // 设置图像大小和形状
                      />
                    </div>
                  </Col>
                  <Col span={16}>
                    {/* 右侧的客户资料 */}
                    <Row gutter={16}>
                      {" "}
                      {/* gutter 可以用来设置列之间的间距 */}
                      <Col span={8}>
                        {/* 第一个客户资料部分 */}
                        <div>
                          <p>FN</p>
                          <h3>{formValues.first_name}</h3>
                        </div>
                      </Col>
                      <Col span={8}>
                        {/* 第二个客户资料部分 */}
                        <div>
                          <p>MN</p>
                          <h3>{formValues.middle_name}</h3>
                        </div>
                      </Col>
                      <Col span={8}>
                        {/* 第三个户资料部分 */}
                        <div>
                          <p>LN</p>
                          <h3>{formValues.last_name}</h3>
                        </div>
                      </Col>
                    </Row>

                    <Divider dashed style={{ margin: "5px auto" }} />

                    <Row gutter={16}>
                      {" "}
                      {/* gutter 可以用来设置列之间的间距 */}
                      <Col span={8}>
                        {/* 第一个客户资料部分 */}
                        <div>
                          <p>HAIR</p>
                          <h3>{formValues.hair}</h3>
                        </div>
                      </Col>
                      <Col span={8}>
                        {/* 第二个客户资料部分 */}
                        <div>
                          <p>EYES</p>
                          <h3>{formValues.eyes}</h3>
                        </div>
                      </Col>
                      <Col span={8}>
                        {/* 第三个客户资料部分 */}
                        <div>
                          <p>Gender</p>
                          <h3>{formValues.gender}</h3>
                        </div>
                      </Col>
                    </Row>

                    <Divider dashed style={{ margin: "5px auto" }} />

                    <Row gutter={16}>
                      {" "}
                      {/* gutter 可以用来设置列之间的间距 */}
                      <Col span={8}>
                        {/* 第一个客户资料部分 */}
                        <div>
                          <p>DOB</p>
                          <h3 style={{ fontSize: "12px", fontWeight: "bold" }}>
                            {formValues.dob}
                          </h3>
                        </div>
                      </Col>
                      <Col span={8}>
                        {/* 第二个客户资料部分 */}
                        <div>
                          <p>WEIGHT</p>
                          <h3>{formValues.weight}</h3>
                        </div>
                      </Col>
                      <Col span={8}>
                        {/* 第三个客户资料部分 */}
                        <div>
                          <p>HEIGHT</p>
                          <h3>{formValues.height}</h3>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      {" "}
                      {/* gutter 可以用来设置列之间的间距 */}
                      <Col span={24}>
                        <p style={{ textAlign: "left" }}> Address</p>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      {" "}
                      {/* gutter 可以用来设置列之间的间距 */}
                      <Col span={24}>
                        <h3 style={{ textAlign: "left" }}>
                          {formValues.address},{formValues.state}{" "}
                          {formValues.zip_code}
                        </h3>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", height: "50px", fontSize: "16px" }}
              >
                Add to Cart
              </Button>
            </Form>
            <Button
              type="primary"
              hidden={!cartItemsCount}
              style={{
                width: "100%",
                height: "50px",
                fontSize: "16px",
                marginTop: "10px",
              }}
              onClick={handleCheckout}
            >
              Checkout
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default OrderForm;
